import { toast } from 'react-toastify'

export function showSuccess(message) {
    toast.success(message);
}
export function showError(message) {
    toast.error(message);
}
export function showWarning(message) {
    toast.warning(message);
}
export function validateMaks(text) {
    let pattern = /^[1-9]\d{0,2}$/.test(text);
    return pattern;
}
export function validateClass(text) {
    let pattern = /^[1-9][0]?$/.test(text);
    return pattern;
}
export function validateClassCode(text) {
    let pattern = /^(?=[MDCLXVI])M*(C[MD]|D?C{0,3})(X[CL]|L?X{0,3})(I[XV]|V?I{0,3})$/.test(text);
    return pattern;
}
export function validateText(text) {
    let pattern = /^[a-zA-Z0-9\s]{3,}$/i.test(text);
    return pattern;
}
export function validateShortCode(text) {
    let pattern = /^[A-Z0-9]{2,}$/.test(text);
    return pattern;
}
export function validateName(name) {
    let pattern = /^[a-zA-Z]{2,40}( [a-zA-Z]{2,40})+$/.test(name);
    return pattern;
}
export function validateTextOnly(text) {
    let pattern = /^[a-zA-Z]{3,}$/.test(text);
    return pattern;
}
export function validateSubheading(text) {
    let pattern = /^[^\s\.,-][a-zA-Z0-9\.,]*([\s]?[a-zA-Z0-9,\.])*$/.test(text);
    return pattern;
}
export function validateEmail(email) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(email) === false) {
        return true;
    }
}
export function validatePhone(phone) {
    if (parseInt(phone) == 0) {
        return false;
    }
    var validatePhone = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(phone);
    return validatePhone;
}
export function validatePassword(password) {
    //eslint-disable-next-line
    var anUpperCase = /[A-Z]/;
    //eslint-disable-next-line
    var aLowerCase = /[a-z]/;
    //eslint-disable-next-line
    var aNumber = /[0-9]/;
    //eslint-disable-next-line
    var aSpecial = /[!|@|#|$|%|^|&|*|(|)|-|_]/;
    var obj = {};
    obj.result = true;

    if (password.length < 6) {
        obj.result = false;
        obj.error = "Password Strength must be minimum 6 characters!"
        return obj;
    }

    var numUpper = 0;
    var numNums = 0;
    var numSpecials = 0;
    for (var i = 0; i < password.length; i++) {
        if (anUpperCase.test(password[i]))
            numUpper++;
        else if (aNumber.test(password[i]))
            numNums++;
        else if (aSpecial.test(password[i]))
            numSpecials++;
    }

    if (numUpper < 1 || numNums < 1 || numSpecials < 1) {
        obj.result = false;
        obj.error = "Strong Password Recomended 1 Uppercase 1 Numeric & 1 special character!";
        return obj;
    }
    return obj;
}
export function deviceType() {
    var obj = {};
    //eslint-disable-next-line
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) //eslint-disable-next-line
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
        obj.type = 'mobile';
        obj.prefix = 'M';
    }
    else {
        obj.type = 'web';
        obj.prefix = 'W';
    }
    return obj;
}