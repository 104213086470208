import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
	Row,
	Col,
	Card,
	Table,
	Modal,
	Button,
	ProgressBar,
} from "react-bootstrap";
import Moment from 'react-moment';
import swal from "sweetalert";

import { loadingRoleAction, getRoleList } from '../../../store/actions/RoleActions';
import { loadingEmployeeAction, addEmployeeData, getEmployeeList, updateEmployeeStatusData, UpdateEmployeeData, removeEmployeeData } from '../../../store/actions/EmployeeActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';


const ManageUsers = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();

	let errorsObj = { role: '', userName: '', email: '', employeeId: '', password: '' };
	const [errors, setErrors] = useState(errorsObj);
	const [roleName, setRoleName] = useState('');
	const [userName, setUserName] = useState('');
	const [email, setEmail] = useState('');
	const [employeeId, setEmployeeId] = useState('');
	const [password, setPassword] = useState('');
	const [empId, setEmpId] = useState('');
	const [addModal, setAddModal] = useState(false);
	const [editModal, setEditModal] = useState(false);

	useEffect(() => {
		dispatch(globalLoadingAction(true));
		dispatch(getRoleList(props.companyId, props.history));
		dispatch(getEmployeeList(props.history));
	}, []);

	function onSubmit(e) {

		e.preventDefault();

		let error = false;
		const errorObj = { ...errorsObj };

		if (roleName === '') {
			errorObj.role = 'Role name is Required';
			error = true;
		}
		if (userName === '') {
			errorObj.userName = 'User name is Required';
			error = true;
		}
		if (email === '') {
			errorObj.email = 'Email is Required';
			error = true;
		}
		if (employeeId === '') {
			errorObj.employeeId = 'Employee id is Required';
			error = true;
		}
		if (password === '') {
			errorObj.password = 'Password id is Required';
			error = true;
		}
		setErrors(errorObj);
		if (error) {
			return;
		}
		dispatch(globalLoadingAction(true));
		dispatch(addEmployeeData(roleName, userName, email, employeeId, password, props.history));
		setRoleName('');
		setUserName('');
		setEmail('');
		setEmployeeId('');
		setPassword('');
		setAddModal(false);
	}

	const onEditHandler = (item) => {
		setEditModal(true);
		setRoleName(item.role_id);
		setUserName(item.name);
		setEmail(item.email);
		setEmpId(item._id);
	}

	function onUpdate(e) {

		e.preventDefault();

		let error = false;
		const errorObj = { ...errorsObj };

		if (roleName === '') {
			errorObj.role = 'Role name is Required';
			error = true;
		}
		if (userName === '') {
			errorObj.userName = 'User name is Required';
			error = true;
		}
		if (email === '') {
			errorObj.email = 'Email is Required';
			error = true;
		}
		setErrors(errorObj);
		if (error) {
			return;
		}
		dispatch(globalLoadingAction(true));
		dispatch(UpdateEmployeeData(empId, roleName, userName, email, '', props.history));
		setRoleName('');
		setUserName('');
		setEmail('');
		setEmpId('');
		setEditModal(false);
	}

	const onStatusHandler = (item) => {
		dispatch(globalLoadingAction(true));
		dispatch(updateEmployeeStatusData(item));
	}

	const onDeleteHandler = (id) => {

		swal({
			title: "Are you sure?",
			text:
				"You want to remove this item !",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				dispatch(removeEmployeeData(id, props.history));
			}
		})

	}

	return (
		<>
			<div className="row">
				<div className="title-body">
					<h3>Manage Users</h3>
					<p>Manage Company/Organization Information such as name, type, location address and contacts.</p>
				</div>
				<div className="account-setting-content">
					<Col lg={12}>
						<Card>
							<Card.Header>
								<div className="add-role-button-view">
									<Link
										href="#"
										onClick={() => setAddModal(true)}
										className="btn btn-success"
									>
										Add User
									</Link>

								</div>
							</Card.Header>
							<Card.Body>
								<Table responsive>
									<thead>
										<tr>
											<th>
												<strong>ID</strong>
											</th>
											<th>
												<strong>NAME</strong>
											</th>
											<th>
												<strong>Email</strong>
											</th>
											<th>
												<strong>Date</strong>
											</th>
											<th>
												<strong>Status</strong>
											</th>
											<th>
												<strong>Action</strong>
											</th>
										</tr>
									</thead>
									<tbody>
										{
											props.employeeList.map((item, index) => {
												return (
													<tr key={index}>
														<td>{item.employee_id}</td>
														<td>{item.name}</td>
														<td>{item.email}</td>
														<td><Moment format="D MMM YYYY" withTitle>{item.created_at}</Moment></td>
														<td>
															<Button
																className="btn-xs"
																variant={item.status == 1 ? "success" : "danger"}
																onClick={() => onStatusHandler(item)}
															>
																{item.status == 1 ? "Active" : "Inactive"}
															</Button>
														</td>
														<td>
															<div className="d-flex">
																<Link
																	onClick={() => onEditHandler(item)}
																	className="btn btn-warning shadow btn-xs sharp me-1"
																>
																	<i className="fas fa-pencil-alt"></i>
																</Link>


																<Button
																	className="btn btn-danger shadow btn-xs sharp"
																	onClick={() => onDeleteHandler(item._id)}
																>
																	<i className="fa fa-trash"></i>
																</Button>
															</div>
														</td>
													</tr>
												);
											})
										}
									</tbody>
								</Table>
							</Card.Body>
						</Card>
					</Col>
				</div>

				<Modal
					className="fade bd-example-modal-sm"
					size="lg"
					show={addModal}
				>
					<div className="add-user-modal-form-view">
						<div className="modal-title-view">
							<h3>Add Employee</h3>
							<p>Manage Company/Organization Information such as name, type, location address and contacts.
							</p>
						</div>
						<div className="basic-form">
							<form onSubmit={onSubmit}>
								<div className="row">
									<div className="form-group form-group-modal-custom mb-3 col-6">
										<label className="mb-1">
											Role name
										</label>
										<div className="select-drop-down">
											<span><i class="fa fa-angle-down"></i></span>
											<select
												defaultValue={"option"}
												class="form-control select-control"
												value={roleName}
												onChange={(e) => setRoleName(e.target.value)}>
												<option>Select role name</option>
												{
													props.roleList.map((item, index) => {
														return <option key={index} value={item._id}>{item.role_name}</option>
													})
												}
											</select>
										</div>
										{errors.role && <div className="text-danger fs-12">{errors.role}</div>}
									</div>
									<div className="form-group form-group-modal-custom mb-3 col-6">
										<label className="mb-1">
											Name
										</label>
										<input className="form-control" placeholder="Enter the role name" value={userName} onChange={(e) => setUserName(e.target.value)} />
										{errors.userName && <div className="text-danger fs-12">{errors.userName}</div>}
									</div>
									<div className="form-group form-group-modal-custom mb-3 col-6">
										<label className="mb-1">
											Email
										</label>
										<input className="form-control" placeholder="Enter the email" value={email} onChange={(e) => setEmail(e.target.value)} />
										{errors.email && <div className="text-danger fs-12">{errors.email}</div>}
									</div>
									<div className="form-group form-group-modal-custom mb-3 col-6">
										<label className="mb-1">
											Empolyee Id
										</label>
										<input className="form-control" placeholder="Enter the employee id" value={employeeId} onChange={(e) => setEmployeeId(e.target.value)} />
										{errors.employeeId && <div className="text-danger fs-12">{errors.employeeId}</div>}
									</div>
									<div className="form-group form-group-modal-custom mb-3 col-6">
										<label className="mb-1">
											Password
										</label>
										<input className="form-control" placeholder="Enter the password" value={password} onChange={(e) => setPassword(e.target.value)} />
										{errors.password && <div className="text-danger fs-12">{errors.password}</div>}
									</div>
								</div>
								<div className="modal-button-group mt-4">
									<button type="button" onClick={() => setAddModal(false)} className="btn form-cencel-btn">
										Cancel
									</button>
									<button type="submit" className="btn btn-primary role-form-btn">
										Save
									</button>
								</div>
							</form>
						</div>

					</div>
				</Modal>

				<Modal
					className="fade bd-example-modal-sm"
					size="lg"
					show={editModal}
				>
					<div className="add-user-modal-form-view">
						<div className="modal-title-view">
							<h3>Edit Employee</h3>
							<p>Manage Company/Organization Information such as name, type, location address and contacts.
							</p>
						</div>
						<div className="basic-form">
							<form onSubmit={onUpdate}>
								<div className="row">
									<div className="form-group form-group-modal-custom mb-3 col-6">
										<label className="mb-1">
											Role name
										</label>
										<div className="select-drop-down">
											<span><i class="fa fa-angle-down"></i></span>
											<select
												defaultValue={"option"}
												class="form-control select-control"
												value={roleName}
												onChange={(e) => setRoleName(e.target.value)}>
												<option>Select role name</option>
												{
													props.roleList.map((item, index) => {
														return <option key={index} value={item._id}>{item.role_name}</option>
													})
												}
											</select>
										</div>
										{errors.role && <div className="text-danger fs-12">{errors.role}</div>}
									</div>
									<div className="form-group form-group-modal-custom mb-3 col-6">
										<label className="mb-1">
											Name
										</label>
										<input className="form-control" placeholder="Enter the role name" value={userName} onChange={(e) => setUserName(e.target.value)} />
										{errors.userName && <div className="text-danger fs-12">{errors.userName}</div>}
									</div>
									<div className="form-group form-group-modal-custom mb-3 col-6">
										<label className="mb-1">
											Email
										</label>
										<input className="form-control" placeholder="Enter the email" value={email} onChange={(e) => setEmail(e.target.value)} />
										{errors.email && <div className="text-danger fs-12">{errors.email}</div>}
									</div>

								</div>
								<div className="modal-button-group mt-4">
									<button type="button" onClick={() => setEditModal(false)} className="btn form-cencel-btn">
										Cancel
									</button>
									<button type="submit" className="btn btn-primary role-form-btn">
										Update
									</button>
								</div>
							</form>
						</div>

					</div>
				</Modal>
			</div>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		showLoading: state.employee.showLoading,
		roleList: state.role.roleList,
		employeeList: state.employee.employeeList,
		companyId: state.auth.companyId,
	};
};
export default connect(mapStateToProps)(ManageUsers);