export const CHECK_TOKEN_EXIST_API = "users/check-login";

//param - null
export const GET_COUNTRIES_LIST_API = "countries";

//param - null
export const GET_STATES_LIST_API = "states";

//param - null
export const GET_CITYS_LIST_API = "cities";

//param - email
export const SET_EMAIL_OTP_API = "email_verification/send-otp";

//param - email,OTP
export const VERIFY_OTP_API = "email_verification/verify-otp";

//param - email,country,password
export const USER_REGISTRATION_API = "users/registration";

//param - email,password
export const USER_LOGIN_API = "users/login";

//param - first_name, last_name, phone_no
export const USER_SETUP_ACCOUNT_API = "users/setup-account";

//param - user_id,company_name, company_phone, company_address,designation,country,state,city,pincode,industry,website_url,address1,address2
export const COMPANY_SETUP_DETAILS_API = "company/set-organization-details";

//param - user_id
export const GET_USER_PROFILE_DETAILS_API = "users/profile-details";

//param - email
export const SEND_FORGOT_PASSWORD_OTP_API = "email_verification/send-forgetpassword-otp";

//param - email,otp
export const VERIFY_FORGOT_PASSWORD_OTP_API = "email_verification/verify-forgetpassword-otp";

//param - email,password
export const RESET_PASSWORD_API = "users/reset-password";

//param - NULL
export const GET_PERMISSION_LIST_API = "permissions";

//param - role_name,company_id,permissions
export const ADD_ROLE_API = "roles/add";

//param - role_id, role_name, permissions
export const UPDATE_ROLE_API = "roles/update";

//param - company_id
export const GET_ROLE_API = "roles";

//param - role_id, status
export const UPDATE_ROLE_STATUS_API = "roles/change-status";

//param - role_id
export const REMOVE_ROLE_API = "roles/remove";

//param - role_id, name, email, employee_id, password
export const ADD_EMPLOYEE_API = "employee/add";

//param - emp_id,role_id, name, email, password
export const UPDATE_EMPLOYEE_API = "employee/update";

//param -employee_id,
export const GET_EMPLOYEE_LIST_API = "employee";

//param - emp_id, status
export const UPDATE_EMPLOYEE_STATUS_API = "employee/change-status";

//param - emp_id
export const REMOVE_EMPLOYEE_RECORD_API = "employee/remove";

//param - first_name, last_name, phone_no, designation, country, country_code, profile_pic
export const UPDATE_PROFILE_API = "users/update-profile-details";

//param - company_id, company_name, address1, address2, country, country_code, state, city, company_phone, pincode, industry, website_url
export const UPDATE_CONPANY_DETAILS_API = "company/update-company-details";

//param - user_id
export const CHECK_SUBSCRIPTION_API = "users/check-subscription";
