import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Select from "react-select";
import GlobalConfigs from "../../../configs/GlobalConfigs";
import {
    updateProfileDetails
} from '../../../store/actions/ProfileActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import { getCountryList } from '../../../store/actions/CountryAction';

import userImg from "../../../images/avatar.png";

const UserProfile = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();

    let errorsObj = { firstName: '', lastName: '', phone: '', role: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [country, setCountry] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [file, setFile] = useState('');
    const [profile, setProfile] = useState('');
    const [role, setRole] = useState('');


    useEffect(() => {
        dispatch(getCountryList(props.history));
        let getData = localStorage.getItem('userDetails');
        let firstName = JSON.parse(getData).first_name;
        let lastName = JSON.parse(getData).last_name;
        let phone = JSON.parse(getData).phone_no;
        let email = JSON.parse(getData).email;
        let designation = JSON.parse(getData).designation;
        let country = JSON.parse(getData).country;
        let profile = JSON.parse(getData).profile_pic;
        let countryCode = JSON.parse(getData).country_code;
        setFirstName(firstName);
        setLastName(lastName);
        setEmail(email);
        setPhone(phone);
        setRole(designation);
        setCountry(country);
        setCountryCode(countryCode);
        setProfile(profile);
    }, []);

    function onSubmit(e) {

        e.preventDefault();

        let error = false;
        const errorObj = { ...errorsObj };

        if (firstName === '') {
            errorObj.firstName = 'First name is Required';
            error = true;
        }
        if (lastName === '') {
            errorObj.lastName = 'Last name is Required';
            error = true;
        }
        if (phone === '') {
            errorObj.phone = 'Phone is Required';
            error = true;
        }
        if (role === '') {
            errorObj.role = 'Role is Required';
            error = true;
        }

        setErrors(errorObj);
        if (error) {
            return;
        }

        dispatch(globalLoadingAction(true));
        dispatch(updateProfileDetails(country, countryCode, firstName, lastName, phone, role, file, props.history));
    }

    const onImageHandler = (event) => {

        let file = event.target.files[0];
        const fr = new FileReader();
        return new Promise((resolve, reject) => {
            fr.addEventListener("load", e => {
                let image = fr.result;
                setFile(file);
                setProfile(image)
            });
            fr.addEventListener("error", e => {
                reject();
            });
            fr.readAsDataURL(file);
        });
    }

    const onGoBackHandler = () => {
        history.goBack();
    }

    const updatedCountries = props.countryList.map((country) => ({
        label: country.name,
        value: country.name,
        ...country
    }));

    const onChangecountry = (option) => {
        setCountry(option.name);
        setCountryCode(option.phone_code)
    }

    return (
        <>
            <div className="row">
                <div className="title-body mb-3">
                    <h3>Profile</h3>
                    <p>Manage Company/Organization Information such as name, type, location address and contacts.</p>
                </div>
                <div className="col-xl-6 col-xxl-12">
                    <div className="row">

                        <div className="col-xl-12">
                            <div className="card pt-5 pb-5">
                                <div className="row">
                                    <div className="col-xl-3"></div>
                                    <div className="col-xl-6">

                                        <div className="card-body">
                                            <div className="basic-form">

                                                <form onSubmit={onSubmit}>

                                                    <div className="profile-view-section">

                                                        <div className="user-profile-thum">
                                                            <img src={profile ? profile : userImg} alt="user" />
                                                            <div className="custom-file-input">
                                                                <label htmlFor="uploadUserImage"><i class="fa-solid fa-camera"></i></label>
                                                                <input
                                                                    type="file"
                                                                    id="uploadUserImage"
                                                                    accept="image/*"
                                                                    onChange={onImageHandler}
                                                                    hidden
                                                                />
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className="form-group form-group-modal-custom mb-3">
                                                        <label className="mb-1">
                                                            Country
                                                        </label>
                                                        <Select
                                                            id="editCountry"
                                                            name="editCountry"
                                                            label="country"
                                                            classNames="select-custom-form"
                                                            options={updatedCountries}
                                                            value={updatedCountries.filter(obj => obj.name === country)}
                                                            placeholder="Country"
                                                            onChange={onChangecountry}
                                                        />

                                                    </div>
                                                    <div className="form-group form-group-modal-custom mb-3">
                                                        <label className="mb-1">
                                                            First Name
                                                        </label>
                                                        <input type="text" className="form-control" placeholder="Enter the first name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                                        {errors.firstName && <div className="text-danger fs-12">{errors.firstName}</div>}
                                                    </div>
                                                    <div className="form-group form-group-modal-custom mb-3">
                                                        <label className="mb-1">
                                                            Last Name
                                                        </label>
                                                        <input type="text" className="form-control" placeholder="Enter the last name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                                        {errors.lastName && <div className="text-danger fs-12">{errors.lastName}</div>}
                                                    </div>
                                                    <div className="form-group form-group-modal-custom mb-3">
                                                        <label className="mb-1">
                                                            Email
                                                        </label>
                                                        <input type="text" className="form-control" placeholder="Enter the email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                    </div>

                                                    {/* <div className="form-group form-group-modal-custom mb-3">
                                                        <label className="mb-1">
                                                            Phone
                                                        </label>
                                                        <input type="text" className="form-control" placeholder="Enter the phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                                        {errors.phone && <div className="text-danger fs-12">{errors.phone}</div>}
                                                    </div> */}

                                                    <div className="form-group col-12 mb-3">
                                                        <label className="mb-1">
                                                            Phone
                                                        </label>
                                                        <div className="phone-number-form-group">
                                                            <input type="text" class="country-code-control" disabled value={countryCode ? "+" + countryCode : ""} />
                                                            <input type="text" class="phone-control" placeholder="Company Phone No.*" value={phone} onChange={(e) => setPhone(e.target.value)}
                                                            />
                                                            {/* <div className="tooltip-info"><i class="fa fa-info"></i>
                                                                <span className="tooltip-text">Tooltip text</span>
                                                            </div> */}
                                                        </div>
                                                    </div>

                                                    <div className="form-group form-group-modal-custom mb-3">
                                                        <label className="mb-1">
                                                            Role name
                                                        </label>
                                                        <div className="select-drop-down">
                                                            <span><i class="fa fa-angle-down"></i></span>
                                                            <select
                                                                defaultValue={"option"}
                                                                class="form-control select-control"
                                                                value={role}
                                                                onChange={(e) => setRole(e.target.value)}>
                                                                <option value="">Select Role</option>
                                                                <option value="administrator">Administrator</option>
                                                                <option value="super admin">Super Admin</option>
                                                                <option value="admin">Admin</option>
                                                            </select>
                                                        </div>
                                                        {errors.role && <div className="text-danger fs-12">{errors.role}</div>}
                                                    </div>

                                                    <div className="modal-button-group mt-5">
                                                        <button onClick={onGoBackHandler} type="button" className="btn form-cencel-btn">
                                                            Cancel
                                                        </button>
                                                        <button type="submit" className="btn btn-primary role-form-btn" disabled={props.showLoading}>
                                                            Save
                                                        </button>
                                                    </div>

                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-xl-3"></div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>


            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        showLoading: state.role.showLoading,
        permissionsList: state.role.permissionsList,
        countryList: state.country.countryList,
    };
};
export default connect(mapStateToProps)(UserProfile);