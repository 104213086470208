import axios from 'axios';
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';


export function checkSubscribtion() {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let user_id = JSON.parse(getData)._id;

    const postData = {
        user_id
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.CHECK_SUBSCRIPTION_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

