import React, { useContext } from "react";

/// React router dom
import { BrowserRouter, Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";

/// Dashboard
import Home from "./components/Dashboard/Home";

//Profile
import UserProfile from "./components/Profile/UserProfile";
import CompanyProfile from "./components/Profile/CompanyProfile"

//Settings
import ManageUsers from "./components/Settings/ManageUsers";
import Settings from "./components/Settings/Settings";
import RoleManagement from "./components/Settings/RoleManagement";
import AddRole from "./components/Settings/AddRole";
import UpdateRole from "./components/Settings/UpdateRole";

//Redux
import { ThemeContext } from "../context/ThemeContext";

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const routes = [

    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },

    /// Profile
    { url: "user-profile", component: UserProfile },
    { url: "company-profile", component: CompanyProfile },

    /// system setting
    { url: "manage-users", component: ManageUsers },
    { url: "settings", component: Settings },
    { url: "role-management", component: RoleManagement },
    { url: "add-role", component: AddRole },
    { url: "update-role", component: UpdateRole },
  ];

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${menuToggle ? "menu-toggle" : ""
          }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}
          style={{ minHeight: window.screen.height - 45 }}
        >
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <ScrollToTop />
    </>
  );
};

export default Markup;
