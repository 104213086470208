import todoReducers from './Reducers';
import { combineReducers } from 'redux';
import { GlobalReducer } from './GlobalReducer';
import { AuthReducer } from './AuthReducer';
import { CountryReducer } from './CountryReducer';
import { RoleReducer } from './RoleReducer';
import { EmployeeReducer } from './EmployeeReducer';
import { ProfleReducer } from './ProfleReducer';
import { SubscribtionReducer } from './SubscribtionReducer';

const rootReducers = combineReducers({
	global: GlobalReducer,
	auth: AuthReducer,
	country: CountryReducer,
	role: RoleReducer,
	employee: EmployeeReducer,
	profile: ProfleReducer,
	subscribtion: SubscribtionReducer,
})

export default rootReducers;