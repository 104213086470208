import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
	loadingToggleAction, getProfileDetails,
} from '../../../store/actions/AuthActions';
import {
	checkSubscribtionDetails
} from '../../../store/actions/SubscribtionActions';

const Home = (props) => {

	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		//dispatch(loadingToggleAction(true));
		dispatch(getProfileDetails(props.history));
		dispatch(checkSubscribtionDetails(props.history));
	}, []);

	return (
		<>
			<div className="row">
				<div className="title-body">
					<h3>Account</h3>
				</div>

				<div className="user-content-view">
					<div className="row">
						<div className="col-xl-11 col-md-12">
							<div className="row">
								<div className="col-xl-4 col-md-6">
									<div className="user-card">
										<div className="add-user-card">
											<i class="fa fa-clock" aria-hidden="true"></i>
										</div>
										<div className="add-user-content">
											<h2>Capture time</h2>
										</div>
									</div>
								</div>
								<div className="col-xl-4 col-md-6">
									<div className="user-card">
										<div className="add-user-card">
											<i class="fa fa-user-plus" aria-hidden="true"></i>
										</div>
										<div className="add-user-content">
											<h2>Setup client</h2>
										</div>
									</div>
								</div>
								<div className="col-xl-4 col-md-6">
									<div className="user-card">
										<div className="add-user-card">
											<i class="fa fa-link" aria-hidden="true"></i>
										</div>
										<div className="add-user-content">
											<h2>Send Invitation link</h2>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-1 col-md-12">
						</div>
					</div>
				</div>

				<div className="subscription-content-view">
					<div className="row">
						<div className="col-xl-11 col-md-12">
							<div className="row">
								<div className="col-xl-4 col-md-6">
									<div className="organization-info-card">
										<div className="organization-info-title-view">
											<div>
												<h3>organization info</h3>
											</div>
											<div>
												<i class="fa fa-pencil" aria-hidden="true"></i>
											</div>
										</div>

										<div className="organization-info-form-view">
											<div className="form-group organization-info-form-group">
												<label>Company/Organization</label>
												<input
													type="text"
													className="form-control"
													placeholder="company"
													value={props.companyDetails.company_name}
												/>
											</div>
											<div className="form-group organization-info-form-group">
												<label>Entity type</label>
												<input
													type="text"
													className="form-control"
													placeholder="type"
													value={props.companyDetails.industry}
												/>
											</div>
											<div className="form-group organization-info-form-group">
												<label>Main Location</label>
												<textarea className="form-control" placeholder="location" value={props.companyDetails.address1}>
												</textarea>
											</div>
										</div>
									</div>
								</div>

								<div className="col-xl-8 col-md-6">
									<div className="subscribtion-card">
										<div className="subscribtion-card-title-view">
											<h3>Subscribtion</h3>
										</div>
										<div className="subscribtion-content">
											<div className="subscribtion-plane-view">
												<div className="subscribtion-plane-left">
													{/* <button className="subscribtion-plane-btn">Active</button> */}
													<button className="subscribtion-plane-btn">Free Trail</button>
													{/* <h3>Clock Me Now Premier Plane</h3> */}
													<h3>{props.subscribtionStatus ? "Free subscribed trial period left" : "Free subsciption expired"}</h3>
													{/* <p>Subscribed since Jun 15,2022</p> */}
												</div>
												<div className="subscribtion-plane-right">
													{/* <h3>$10.00/Mth</h3> */}
													{props.subscribtionStatus ? <h3>{props.subscribtionStatus} Days</h3> : <button className="subscribtion-expired-btn">Subscribe Now</button>}
												</div>
											</div>
											<div className="subscribtion-plane-dis">
												<p>Manage you subscribtion, Plans, Payment method and payment history.</p>
											</div>
											<div className="subscribtion-plane-btn-content">
												<button>Change Plane</button>
												<button>Upgrade Plane</button>
												<button>Cancel Plane</button>
												<button>Payment Method</button>
												<button>Payment History</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-1 col-md-12">
						</div>
					</div>
				</div>

				<div className="account-setting-content mt-4">
					<div className="row">
						<div className="col-xl-11 col-md-12">
							<div className="row">
								<div className="col-xl-4 col-md-6">
									<div className="account-setting-card">
										<h4>Application Settings</h4>
										<div className="account-setting-option">
											<div className="account-setting-item">
												<div><p>Time Reporting</p></div>
												<div className="switch-btn-view">
													<label class="switch">
														<input type="checkbox" checked />
														<span class="slider round"></span>
													</label>
												</div>
											</div>
											<div className="account-setting-item">
												<div><p>Biling</p></div>
												<div className="switch-btn-view">
													<label class="switch">
														<input type="checkbox" />
														<span class="slider round"></span>
													</label>
												</div>
											</div>
											<div className="account-setting-item">
												<div><p>Expenses</p></div>
												<div className="switch-btn-view">
													<label class="switch">
														<input type="checkbox" checked />
														<span class="slider round"></span>
													</label>
												</div>
											</div>
											<div className="account-setting-item">
												<div><p>Performance</p></div>
												<div className="switch-btn-view">
													<label class="switch">
														<input type="checkbox" />
														<span class="slider round"></span>
													</label>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-4 col-md-6">
									<div className="account-setting-card">
										<h4>Features</h4>
										<div className="account-setting-option">
											<div className="account-setting-item">
												<div><p>Work Shifts</p></div>
												<div className="switch-btn-view">
													<label class="switch">
														<input type="checkbox" checked />
														<span class="slider round"></span>
													</label>
												</div>
											</div>
											<div className="account-setting-item">
												<div><p>Groups & Trees</p></div>
												<div className="switch-btn-view">
													<label class="switch">
														<input type="checkbox" />
														<span class="slider round"></span>
													</label>
												</div>
											</div>
											<div className="account-setting-item">
												<div><p>Classification</p></div>
												<div className="switch-btn-view">
													<label class="switch">
														<input type="checkbox" checked />
														<span class="slider round"></span>
													</label>
												</div>
											</div>
											<div className="account-setting-item">
												<div><p>Location Logging</p></div>
												<div className="switch-btn-view">
													<label class="switch">
														<input type="checkbox" />
														<span class="slider round"></span>
													</label>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-4 col-md-6">
									<div className="account-setting-card">
										<h4>Security</h4>
										<div className="account-setting-option">
											<div className="account-setting-item">
												<div><p>Security Roles</p></div>
												<div></div>
											</div>
											<div className="account-setting-item">
												<div><p>Manage User</p></div>
												<div></div>
											</div>
											<div className="account-setting-item">
												<div><p>Import & Intergration</p></div>
												<div></div>
											</div>
											<div className="account-setting-item">
												<div><p>Security Reports</p></div>
												<div></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-1 col-md-12">
						</div>
					</div>
				</div>

			</div>
		</>
	)
}
const mapStateToProps = (state) => {
	return {
		showLoading: state.auth.showLoading,
		companyDetails: state.auth.company_details,
		subscribtionStatus: state.subscribtion.remainingDay
	};
};
export default connect(mapStateToProps)(Home);