import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import swal from "sweetalert";
import Spinner from 'react-bootstrap/Spinner';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import {
	Row,
	Col,
	Card,
	Table,
	Badge,
	Dropdown,
	ProgressBar,
	Button
} from "react-bootstrap";

import {
	loadingRoleAction, getRoleList, updateRoleStatusData, removeRoleData
} from '../../../store/actions/RoleActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';



const RoleManagement = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		dispatch(globalLoadingAction(true));
		dispatch(getRoleList(props.companyId, props.history));
	}, []);

	const onStatusHandler = (item) => {
		dispatch(globalLoadingAction(true));
		dispatch(updateRoleStatusData(item, props.history));
	}

	const onDeleteHandler = (id) => {
		swal({
			title: "Are you sure?",
			text:
				"You want to remove this item !",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				dispatch(removeRoleData(id, props.history));
			}
		})
	}

	return (
		<>
			<div className="row">
				<div className="title-body">
					<h3>Role Management</h3>
					<p>Manage Company/Organization Information such as name, type, location address and contacts.</p>
				</div>
				{/* {console.log('roleList', props.roleList)} */}
				<div className="account-setting-content">
					<Col lg={12}>
						<Card>
							<Card.Header>
								<div className="add-role-button-view">
									<Link
										to={"/add-role"}
										className="btn btn-success"
									>
										Add Role
									</Link>
								</div>
							</Card.Header>
							<Card.Body>
								<Table responsive>
									<thead>
										<tr>
											<th>
												<strong>ROLE NAME</strong>
											</th>
											<th>
												<strong>Status</strong>
											</th>
											<th>
												<strong>Action</strong>
											</th>
										</tr>
									</thead>
									<tbody>

										{
											props.roleList.map((item, index) => {
												return (
													<tr key={index}>
														<td>{item.role_name}</td>
														<td>
															{item.is_default == 1 ? null :
																<Button
																	className="btn-xs"
																	variant={item.status == 1 ? "success" : "danger"}
																	onClick={() => onStatusHandler(item)}
																>
																	{item.status == 1 ? "Active" : "Inactive"}
																</Button>
															}
														</td>
														<td>
															{item.is_default == 1 ? null :
																<div className="d-flex">
																	<Link
																		to={{
																			pathname: '/update-role',
																			state: item
																		}}
																		className="btn btn-warning shadow btn-xs sharp me-1"
																	>
																		<i className="fas fa-pencil-alt"></i>
																	</Link>

																	<Button
																		className="btn btn-danger shadow btn-xs sharp"
																		onClick={() => onDeleteHandler(item._id)}
																	>
																		<i className="fa fa-trash"></i>
																	</Button>
																</div>
															}
														</td>
													</tr>
												);
											})
										}
									</tbody>
								</Table>
							</Card.Body>
						</Card>
					</Col>
				</div>

			</div>
		</>
	)
}
const mapStateToProps = (state) => {
	return {
		showLoading: state.role.showLoading,
		roleList: state.role.roleList,
		companyId: state.auth.companyId,
	};
};
export default connect(mapStateToProps)(RoleManagement);