import swal from "sweetalert";
import {
    updateProfile,
} from '../../services/ProfileService';

import {
    LOADING_PROFILE,
    UPDATE_PROFILE,
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from '../actions/GlobalAction';


export function updateProfileDetails(country, countryCode, firstName, lastName, phone, role, file, history) {
    return (dispatch) => {
        updateProfile(country, countryCode, firstName, lastName, phone, role, file)
            .then((response) => {
                console.log('response', response.data);
                if (response.data.status == 200) {
                    // dispatch(updateProfileAction({ "country": country, "country_code": countryCode, "first_name": firstName, "last_name": lastName, "phone_no": phone, "designation": role, "profile_pic": file }));
                    dispatch(updateProfileAction(response.data.data));
                    utility.showSuccess(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateProfileAction(data) {
    return {
        type: UPDATE_PROFILE,
        payload: data,
    };
}
export function loadingProfileAction(status) {
    return {
        type: LOADING_PROFILE,
        payload: status,
    };
}
