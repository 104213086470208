import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import {
    addRoleData, loadingRoleAction, getRolePermissionsData
} from '../../../store/actions/RoleActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';


const AddRole = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();

    let errorsObj = { role: '', permission: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [roleName, setRoleName] = useState('');
    const [permission, setPermission] = useState([]);
    const chackbox = document.querySelectorAll(".form-check-label input");
    const motherChackBox = document.querySelector("#checkAll");

    useEffect(() => {
        dispatch(globalLoadingAction(true));
        dispatch(loadingRoleAction(true));
        dispatch(getRolePermissionsData(props.history));
    }, []);

    function onSubmit(e) {

        e.preventDefault();

        let error = false;
        const errorObj = { ...errorsObj };
        if (roleName === '') {
            errorObj.role = 'Role name is Required';
            error = true;
        }
        if (permission.length === 0) {
            errorObj.permission = 'Permission access is Required';
            error = true;

        }
        setErrors(errorObj);
        if (error) {
            return;
        }

        dispatch(globalLoadingAction(true));
        dispatch(addRoleData(roleName, permission, props.history));
        setRoleName('');
        setPermission([]);
        chackbox.forEach((val, index) => {
            chackbox[index].checked = false;
        });
    }

    const onChackboxHandlerMain = (element, item) => {

        let array = [...permission];

        if (element.target.checked == true) {
            array.push(item._id);
        } else {
            const index = array.findIndex((val) => val === item._id);
            array.splice(index, 1);
        }
        //console.log('hello', array);
        setPermission(array);

    }


    const onChackboxHandlerSub = (element, item, subItem) => {

        let dataArray = props.permissionsList;
        let array = [...permission];
        let parentId = array.includes(item._id);

        if (element.target.checked == true) {

            if (parentId) {
                array.push(subItem._id);

            } else {

                document.querySelector(`#check${item._id}`).checked = true;
                array.push(item._id, subItem._id);
            }

        } else {

            let ins = null;
            const subIndex = array.findIndex((val) => val === subItem._id);
            array.splice(subIndex, 1);

            for (let i = 0; dataArray.length > i; i++) {
                if (dataArray[i].parent_id == item.menu_id) {
                    let removeId = array.includes(dataArray[i]._id);
                    if (removeId) {
                        ins = true;
                        // console.log('id', removeId);
                        break
                    }

                }
            }
            if (!ins) {
                const parentIndex = array.findIndex((val) => val === item._id);
                array.splice(parentIndex, 1);
                document.querySelector(`#check${item._id}`).checked = false;
            }


        }
        //console.log('hello', array);
        setPermission(array);
    }



    const onChackboxHandlerUnderSub = (element, item, subItem, underSubItem) => {


        let dataArray = props.permissionsList;
        let array = [...permission];
        let parentId = array.includes(item._id);
        let secondParentId = array.includes(subItem._id);

        if (element.target.checked == true) {

            if (parentId) {
                document.querySelector(`#check${subItem._id}`).checked = true;
                array.push(subItem._id, underSubItem._id);

            } else if (secondParentId) {
                array.push(underSubItem._id);
            } else {

                document.querySelector(`#check${item._id}`).checked = true;
                document.querySelector(`#check${subItem._id}`).checked = true;
                array.push(item._id, subItem._id, underSubItem._id);
            }

        } else {

            let status = null;
            let subStatus = null;
            const lastMenuIndex = array.findIndex((val) => val === underSubItem._id);
            array.splice(lastMenuIndex, 1);

            for (let i = 0; dataArray.length > i; i++) {
                if (dataArray[i].parent_id == subItem.menu_id) {
                    let removeId = array.includes(dataArray[i]._id);
                    if (removeId) {
                        status = true;
                        break
                    }
                }
            }
            if (!status) {
                const childIndex = array.findIndex((val) => val === subItem._id);
                array.splice(childIndex, 1);
                document.querySelector(`#check${subItem._id}`).checked = false;
            }

            for (let i = 0; dataArray.length > i; i++) {
                if (dataArray[i].parent_id == item.menu_id) {
                    let removeId = array.includes(dataArray[i]._id);
                    if (removeId) {
                        subStatus = true;
                        break
                    }
                }
            }
            if (!subStatus) {
                const parentIndex = array.findIndex((val) => val === item._id);
                array.splice(parentIndex, 1);
                document.querySelector(`#check${item._id}`).checked = false;
            }


        }
        //console.log('hello', array);
        setPermission(array);

    }

    const onGoBackHandler = () => {
        history.goBack();
    }

    return (
        <>
            <div className="row">
                {/* {console.log('hello world', props.permissionsList)} */}
                <div className="title-body mb-3">
                    <h3>Add Role</h3>
                    <p>Manage Company/Organization Information such as name, type, location address and contacts.</p>
                </div>
                <div className="col-xl-6 col-xxl-12">
                    <div className="row">

                        <div className="col-xl-12">
                            <div className="card pt-5 pb-5">
                                <div className="row">
                                    <div className="col-xl-3"></div>
                                    <div className="col-xl-6">

                                        <div className="card-body">
                                            <div className="basic-form">

                                                <form onSubmit={onSubmit}>

                                                    <div className="form-group mb-3">
                                                        <label className="mb-1">
                                                            <strong>Role name</strong>
                                                        </label>
                                                        <input className="form-control" placeholder="Enter the role name" value={roleName} onChange={(e) => setRoleName(e.target.value)} />
                                                        {errors.role && <div className="text-danger fs-12">{errors.role}</div>}
                                                    </div>

                                                    <div className="role-menu-container">

                                                        {
                                                            props.permissionsList.map((item, index) => {
                                                                if (item.parent_id == 0) {
                                                                    return (
                                                                        <div className="role-menu-item">
                                                                            <div className="form-group mt-3">
                                                                                <div key={index} className="form-check">
                                                                                    <label className="form-check-label">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="form-check-input"
                                                                                            id={`check${item._id}`}
                                                                                            onClick={(e) => onChackboxHandlerMain(e, item)}
                                                                                        />
                                                                                        {item.title}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                props.permissionsList.map((data, i) => {
                                                                                    if (data.parent_id == item.menu_id) {
                                                                                        return (
                                                                                            <div className="form-group form-check-inline sub-checkMark">
                                                                                                <div key={index} className="form-check">
                                                                                                    <label className="form-check-label">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="form-check-input"
                                                                                                            id={`check${data._id}`}
                                                                                                            onClick={(e) => onChackboxHandlerSub(e, item, data)}
                                                                                                        />
                                                                                                        {data.title}
                                                                                                    </label>
                                                                                                </div>

                                                                                                {
                                                                                                    props.permissionsList.map((subData, j) => {
                                                                                                        if (subData.parent_id == data.menu_id) {
                                                                                                            return (

                                                                                                                <label className="form-check-label sub-checkMark">
                                                                                                                    <input
                                                                                                                        type="checkbox"
                                                                                                                        className="form-check-input"
                                                                                                                        id={`check${subData._id}`}
                                                                                                                        onClick={(e) => onChackboxHandlerUnderSub(e, item, data, subData)}
                                                                                                                    />
                                                                                                                    {subData.title}
                                                                                                                </label>

                                                                                                            )
                                                                                                        }
                                                                                                    })
                                                                                                }

                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                })
                                                                            }



                                                                        </div>
                                                                    );
                                                                }
                                                            })
                                                        }

                                                        {/* {errors.permission && <div className="text-danger fs-12">{errors.permission}</div>} */}

                                                    </div>

                                                    <div className="modal-button-group mt-5">
                                                        <button onClick={onGoBackHandler} type="button" className="btn form-cencel-btn">
                                                            Cancel
                                                        </button>
                                                        <button type="submit" className="btn btn-primary role-form-btn" disabled={props.showLoading}>
                                                            Save
                                                        </button>
                                                    </div>

                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-xl-3"></div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>


            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        showLoading: state.role.showLoading,
        permissionsList: state.role.permissionsList,
    };
};
export default connect(mapStateToProps)(AddRole);