import swal from "sweetalert";
import {
    checkSubscribtion,
} from '../../services/SubscribtionService';

import {
    LOADING_SUBSCRIBTION,
    CHECK_SUBSCRIBTION,
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from '../actions/GlobalAction';


export function checkSubscribtionDetails(history) {
    return (dispatch) => {
        checkSubscribtion()
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(checkSubscribtionAction(response.data.data));
                    //utility.showSuccess(response.data.msg);
                } else {
                    dispatch(checkSubscribtionAction({ "remaining_day": '' }));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function checkSubscribtionAction(data) {
    return {
        type: CHECK_SUBSCRIBTION,
        payload: data,
    };
}
export function loadingSubscribtionAction(status) {
    return {
        type: LOADING_SUBSCRIBTION,
        payload: status,
    };
}
