import {
    LOADING_SUBSCRIBTION,
    CHECK_SUBSCRIBTION
} from '../constants';

const initialState = {
    remainingDay: '',
    showLoading: false,
};

export function SubscribtionReducer(state = initialState, action) {

    if (action.type === CHECK_SUBSCRIBTION) {
        return {
            ...state,
            remainingDay: action.payload.remaining_day,
            showLoading: false,
        };
    }

    if (action.type === LOADING_SUBSCRIBTION) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }
    return state;
}


