import {
    ADD_ROLE,
    GET_PERMISSION_LIST,
    GET_ROLE,
    UPDATE_ROLE_STATUS,
    REMOVE_ROLE,
    LOADING_ROLE
} from '../constants';

const initialState = {
    permissionsList: [],
    roleList: [],
    showLoading: false,
};

export function RoleReducer(state = initialState, action) {

    if (action.type === GET_PERMISSION_LIST) {
        return {
            ...state,
            permissionsList: action.payload,
            showLoading: false,
        };
    }
    if (action.type === ADD_ROLE) {
        return {
            ...state,
            showLoading: false,
        };
    }
    if (action.type === GET_ROLE) {
        return {
            ...state,
            roleList: action.payload,
            showLoading: false,
        };
    }
    if (action.type === UPDATE_ROLE_STATUS) {
        const list = [...state.roleList];
        const index = list.findIndex((item) => item._id === action.payload._id);
        list[index].status = action.payload.status === 1 ? 0 : 1;
        return {
            ...state,
            roleList: list,
            showLoading: false,
        };
    }
    if (action.type === REMOVE_ROLE) {
        const list = [...state.roleList];
        const index = list.findIndex((item) => item._id === action.payload);
        list.splice(index, 1);
        return {
            ...state,
            roleList: list,
            showLoading: false,
        };
    }
    if (action.type === LOADING_ROLE) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }
    return state;
}


