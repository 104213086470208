/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
/// Scroll
//import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";

import userImg from "../../../images/avatar.png";


class MM extends Component {
	componentDidMount() {
		this.$el = this.el;
		this.mm = new Metismenu(this.$el);
	}
	componentWillUnmount() {
	}
	render() {
		return (
			<div className="mm-wrapper">
				<ul className="metismenu" ref={(el) => (this.el = el)}>
					{this.props.children}
				</ul>
			</div>
		);
	}
}

const SideBar = () => {

	const profileDetails = useSelector(state => state.auth);
	const companyDetails = useSelector(state => state.auth.company_details);

	const {
		iconHover,
		sidebarposition,
		headerposition,
		sidebarLayout,
	} = useContext(ThemeContext);


	useEffect(() => {

		var btn = document.querySelector(".nav-control");
		var aaa = document.querySelector("#main-wrapper");
		function toggleFunc() {
			return aaa.classList.toggle("menu-toggle");
		}
		btn.addEventListener("click", toggleFunc);

		//sidebar icon Heart blast
		var handleheartBlast = document.querySelector('.heart');
		function heartBlast() {
			return handleheartBlast.classList.toggle("heart-blast");
		}
		handleheartBlast.addEventListener('click', heartBlast);

	}, []);

	//For scroll
	const [hideOnScroll, setHideOnScroll] = useState(true);
	useScrollPosition(
		({ prevPos, currPos }) => {
			const isShow = currPos.y > prevPos.y
			if (isShow !== hideOnScroll) setHideOnScroll(isShow)
		},
		[hideOnScroll]
	)
	/// Path
	let path = window.location.pathname;
	path = path.split("/");
	path = path[path.length - 1];

	/// Active menu
	let deshBoard = [
		"",
		"dashboard",
	],
		settings = [
			"settings",
			"manage-users",
			"role-management",
			"add-role",
			"update-role"
		],
		app = [
			"app-profile",
			"post-details",
			"app-calender",
			"email-compose",
			"email-inbox",
			"email-read",
			"ecom-product-grid",
			"ecom-product-list",
			"ecom-product-order",
			"ecom-checkout",
			"ecom-invoice",
			"ecom-customers",
			"post-details",
			"ecom-product-detail",
		];
	return (
		<div
			className={`dlabnav ${iconHover} ${sidebarposition.value === "fixed" &&
				sidebarLayout.value === "horizontal" &&
				headerposition.value === "static"
				? hideOnScroll > 120
					? "fixed"
					: ""
				: ""
				}`}
		>
			<div className="custom-nav-scrollbar">
				{/* <PerfectScrollbar className="dlabnav-scroll"> */}
				<div className="nav-profile">
					<div className="nav-profile-img">
						<div className="nav-profile-img-left">
							<img src={userImg} alt="user" />
						</div>
						<div className="nav-profile-img-right">
							<h5>{profileDetails.firstName} {profileDetails.lastName}</h5>
							<p>{profileDetails.email}</p>
						</div>
					</div>
					<div className="edit-profile-view">
						<Link to="/user-profile"><i class="bi bi-pencil-square"></i> Edit Profile</Link>
					</div>
					<div className="nav-profile-menu">
						<ul>
							<li className="pp-active">
								<Link to="/company-profile">{companyDetails.company_name}</Link>
							</li>
							{/* <li><Link>xcvf IT Solution</Link></li> */}
						</ul>
					</div>
				</div>
				<MM className="metismenu" id="menu">

					<li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
						<Link className="has-arrow" to="#" >
							<i class="bi bi-pencil-square"></i>
							<span className="nav-text">Account</span>
						</Link>
						<ul className={`${path == 'dashboard' ? "mm-collapse mm-show" : "mm-collapse"}`}>
							<li><Link className={`${path === "dashboard" ? "mm-active" : ""}`} to="/dashboard">User Preferences</Link></li>
							<li><Link to="#">Subscription</Link></li>
							<li><Link to="#">Manage Password</Link></li>
							<li><Link to="#">Company information</Link></li>
						</ul>
					</li>

					{/* <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
						<Link className="has-arrow" to="#" >
							<i className="bi bi-grid"></i>
							<span className="nav-text">Dashboard</span>
						</Link>
						<ul className="mm-collapse mm-show">
							<li><Link className={`${path === "dashboard" ? "mm-active" : ""}`} to="/dashboard"> Dashboard Light</Link></li>
							<li><Link className={`${path === "dashboard-dark" ? "mm-active" : ""}`} to="/dashboard-dark"> Dashboard Dark</Link></li>
							<li><Link className={`${path === "schedule" ? "mm-active" : ""}`} to="/schedule">Schedule</Link></li>
							<li><Link className={`${path === "instructors" ? "mm-active" : ""}`} to="/instructors">Instructors</Link></li>
							<li><Link className={`${path === "message" ? "mm-active" : ""}`} to="/message">Message</Link></li>
							<li><Link className={`${path === "activity" ? "mm-active" : ""}`} to="/activity">Activity</Link></li>
							<li><Link className={`${path === "profile" ? "mm-active" : ""}`} to="/profile">Profile</Link></li>

						</ul>
					</li> */}

					{/* <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
						<Link to="dashboard" className="ai-icon" >
							<i class="bi bi-pencil-square"></i>
							<span className="nav-text">Account</span>
						</Link>
						<ul>
							<li><Link to="#">User Preferences</Link></li>
							<li><Link to="#">Subscription</Link></li>
							<li><Link to="#">Manage Password</Link></li>
							<li><Link to="#">Company information</Link></li>
						</ul>
					</li> */}

					{/* <li className={`${app.includes(path) ? "mm-active" : ""}`}>
						<Link to="#" className="ai-icon" >
							<i class="bi bi-people"></i>
							<span className="nav-text">Add Profile</span>
						</Link>
					</li> */}

					{/* <li className={`${app.includes(path) ? "mm-active" : ""}`}>
						<Link to="#" className="ai-icon" >
							<i class="bi bi-person-bounding-box"></i>
							<span className="nav-text">Setup Profile</span>
						</Link>
					</li> */}

					<li className={`${app.includes(path) ? "mm-active" : ""}`}>
						<Link to="#" className="ai-icon" >
							<i class="bi bi-clock"></i>
							<span className="nav-text">Time Capture</span>
						</Link>
					</li>

					<li className={`${app.includes(path) ? "mm-active" : ""}`}>
						<Link to="#" className="ai-icon" >
							<i class="bi bi-journal-text"></i>
							<span className="nav-text">Reporting</span>
						</Link>
					</li>

					{/* <li className={`${app.includes(path) ? "mm-active" : ""}`}>
						<Link to="#" className="ai-icon" >
							<i class="bi bi-person"></i>
							<span className="nav-text">Clients</span>
						</Link>
					</li> */}

					<li className={`${app.includes(path) ? "mm-active" : ""}`}>
						<Link to="#" className="ai-icon" >
							<i class="bi bi-shield-check"></i>
							<span className="nav-text">Approvals</span>
						</Link>
					</li>

					{/* <li className={`${app.includes(path) ? "mm-active" : ""}`}>
						<Link to="#" className="ai-icon" >
							<i class="bi bi-sliders"></i>
							<span className="nav-text">Performance</span>
						</Link>
					</li>
					<li className={`${app.includes(path) ? "mm-active" : ""}`}>
						<Link to="#" className="ai-icon" >
							<i class="bi bi-sort-up"></i>
							<span className="nav-text">User Preferences</span>
						</Link>
					</li> */}
					{/* <li className={`${settings.includes(path) ? "mm-active" : ""}`}>
						<Link to="/settings" className="ai-icon" >
							<i class="bi bi-gear"></i>
							<span className="nav-text">System Settings</span>
						</Link>
					</li> */}

					<li className={`${settings.includes(path) ? "mm-active" : ""}`}>
						<Link className="has-arrow" to="#" >
							<i class="bi bi-gear"></i>
							<span className="nav-text">System Settings</span>
						</Link>
						<ul>

							<li><Link to="#">Manage Profile</Link></li>
							<li><Link className={`${path === "manage-users" ? "mm-active" : ""}`} to="/manage-users">Manage Users</Link></li>
							<li><Link className={`${path === "role-management" ? "mm-active" : ""}`} to="/role-management">Role Management</Link></li>
							<li><Link to="#">Clients</Link></li>
							<li><Link to="#">Classifications</Link></li>
							<li><Link to="#">System Defaults</Link></li>
							<li><Link to="#">Security</Link></li>
							<li className=""><Link className="has-arrow" to="#" >Applications</Link>
								<ul className="">
									<li><Link className="" to="#">Time Reporting</Link></li>
									<li><Link className="" to="#">Performance</Link></li>
									<li><Link className="" to="#">Billing</Link></li>
								</ul>
							</li>
						</ul>
					</li>
					{/* <li className={`${app.includes(path) ? "mm-active" : ""}`}>
						<Link to="#" className="ai-icon" >
							<i class="bi bi-info-circle"></i>
							<span className="nav-text">Support & Help</span>
						</Link>
					</li> */}


				</MM>

				<div className="copyright">
					<p className="fs-12"><span className="heart"></span></p>
				</div>
			</div>
			{/* </PerfectScrollbar> */}
		</div>
	);
};

export default SideBar;
