import axios from 'axios';
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';

export function addEmployee(role_id, name, email, employee_id, password) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let company_id = JSON.parse(getData).company_id;

    const postData = {
        company_id,
        role_id,
        name,
        email,
        employee_id,
        password
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_EMPLOYEE_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateEmployee(emp_id, role_id, name, email, password) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        emp_id,
        role_id,
        name,
        email,
        password
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_EMPLOYEE_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getEmployee() {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let company_id = JSON.parse(getData).company_id;

    const postData = {
        company_id,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_EMPLOYEE_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateEmployeeStatus(emp_id, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        emp_id,
        status
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_EMPLOYEE_STATUS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function removeEmployee(emp_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        emp_id,
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_EMPLOYEE_RECORD_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}
