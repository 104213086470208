//////////////Global//////////

export const GLOBAL_LOADING = "GLOBAL_LOADING";

export const GET_COUNTRY = "GET_COUNTRY";

export const GET_STATE = "GET_STATE";

export const GET_CITY = "GET_CITY";

export const COUNTRY_LOADING = "COUNTRY_LOADING";

//////////////Auth//////////

export const LOADING_TOGGLE_ACTION = "LOADING_TOGGLE_ACTION";

export const LOGIN_CONFIRMED_ACTION = "LOGIN_CONFIRMED_ACTION";

export const LOGIN_FAILED_ACTION = "LOGIN_FAILED_ACTION";

export const LOGOUT_ACTION = "LOGOUT_ACTION";

export const SIGNUP_CONFIRMED_ACTION = "SIGNUP_CONFIRMED_ACTION";

export const SIGNUP_FAILED_ACTION = "SIGNUP_FAILED_ACTION";

export const SET_OTP_ACTION = "SET_OTP_ACTION";

export const GET_PROFILE_AND_COMPANY_DETAILS_ACTION = "GET_PROFILE_AND_COMPANY_DETAILS_ACTION";

export const SETUP_USER_DATA = "SETUP_USER_DATA";

export const SETUP_COMPANY_DATA = "SETUP_COMPANY_DATA";

//////////////Role-management//////////

export const LOADING_ROLE = "LOADING_ROLE";

export const GET_PERMISSION_LIST = "GET_PERMISSION_LIST";

export const ADD_ROLE = "ADD_ROLE";

export const GET_ROLE = "GET_ROLE";

export const UPDATE_ROLE_STATUS = "UPDATE_ROLE_STATUS";

export const REMOVE_ROLE = "REMOVE_ROLE";

//////////////User-management//////////

export const LOADING_EMPLOYEE = "LOADING_EMPLOYEE";

export const ADD_EMPLOYEE = "ADD_EMPLOYEE";

export const GET_EMPLOYEE_LIST = "GET_EMPLOYEE_LIST";

export const UPDATE_EMPLOYEE_STATUS = "UPDATE_EMPLOYEE_STATUS";

export const REMOVE_EMPLOYEE = "REMOVE_EMPLOYEE";

//////////////Profile//////////

export const LOADING_PROFILE = "LOADING_PROFILE";

export const UPDATE_PROFILE = "UPDATE_PROFILE";

export const UPDATE_COMPANY = "UPDATE_COMPANY";

//////////////Subscribtion//////////

export const LOADING_SUBSCRIBTION = "LOADING_SUBSCRIBTION";

export const CHECK_SUBSCRIBTION = "CHECK_SUBSCRIBTION";
