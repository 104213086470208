import {
    LOADING_EMPLOYEE,
    ADD_EMPLOYEE,
    GET_EMPLOYEE_LIST,
    UPDATE_EMPLOYEE_STATUS,
    REMOVE_EMPLOYEE
} from '../constants';

const initialState = {
    employeeList: [],
    showLoading: false,
};

export function EmployeeReducer(state = initialState, action) {

    if (action.type === GET_EMPLOYEE_LIST) {
        return {
            ...state,
            employeeList: action.payload,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_EMPLOYEE_STATUS) {
        const list = [...state.employeeList];
        const index = list.findIndex((item) => item._id === action.payload._id);
        list[index].status = action.payload.status === 1 ? 0 : 1;
        return {
            ...state,
            employeeList: list,
            showLoading: false,
        };
    }

    if (action.type === REMOVE_EMPLOYEE) {
        const list = [...state.employeeList];
        const index = list.findIndex((item) => item._id === action.payload);
        list.splice(index, 1);
        return {
            ...state,
            employeeList: list,
            showLoading: false,
        };
    }

    if (action.type === LOADING_EMPLOYEE) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }
    return state;
}


