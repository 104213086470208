import swal from "sweetalert";
import {
    addEmployee,
    getEmployee,
    updateEmployee,
    removeEmployee,
    updateEmployeeStatus
} from '../../services/EmployeeService';

import {
    LOADING_EMPLOYEE,
    ADD_EMPLOYEE,
    GET_EMPLOYEE_LIST,
    UPDATE_EMPLOYEE_STATUS,
    REMOVE_EMPLOYEE
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from './GlobalAction';


export function addEmployeeData(roleName, userName, email, employeeId, password, history) {
    return (dispatch) => {
        addEmployee(roleName, userName, email, employeeId, password)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getEmployeeList(history));
                    utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function UpdateEmployeeData(emp_id, role_id, emp_Name, email, password, history) {
    return (dispatch) => {
        updateEmployee(emp_id, role_id, emp_Name, email, password)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getEmployeeList(history));
                    utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getEmployeeList(history) {
    return (dispatch) => {
        getEmployee()
            .then((response) => {
                if (response.data.status == 200) {
                    //utility.showSuccess(response.data.msg);
                    dispatch(getEmployeeAction(response.data.data));
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateEmployeeStatusData(item, history) {
    return (dispatch) => {
        updateEmployeeStatus(item._id, item.status === 1 ? 0 : 1)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(updateEmployeeStatusAction(item));
                    utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function removeEmployeeData(id, history) {
    return (dispatch) => {
        removeEmployee(id)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(removeEmployeeAction(id));
                    swal(response.data.msg, { icon: "success", });
                } else {
                    swal(response.data.msg, "error");
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getEmployeeAction(data) {
    return {
        type: GET_EMPLOYEE_LIST,
        payload: data,
    };
}
export function updateEmployeeStatusAction(data) {
    return {
        type: UPDATE_EMPLOYEE_STATUS,
        payload: data,
    };
}
export function removeEmployeeAction(data) {
    return {
        type: REMOVE_EMPLOYEE,
        payload: data,
    };
}
export function loadingEmployeeAction(status) {
    return {
        type: LOADING_EMPLOYEE,
        payload: status,
    };
}
